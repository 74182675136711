<template lang="pug">
.committee-create-edit-dialog 
  CreateEditDialog(
    title="Add Committee"
    :item="committee"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveCommittee"
  )
    template( 
      v-slot:form="{ clone, rules }"
    )
      v-text-field(
        outlined
        dense
        label="Committee Name"
        v-model="clone.name"
        :rules="[rules.required]"
      )
      
      AddressForm(
        :rules="rules"
        :clone="clone"
        addressKey="address"
      )

</template>

<script>
import formValidations from '@/helpers/formValidations'
import { ref } from '@vue/composition-api'
import AddressForm from '@/components/Address.Form'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'

export default {
  name: 'CommitteeCreateEditDialog',

  props: {
    title: {
      type: String,
      default: 'Add Committee Members'
    },
    isOpen: Boolean,
    editingOrg: Object
  },

  components: {
    AddressForm,
    CreateEditDialog
  },

  setup(props, { root: { $store, $FeathersVuex, $snackError, $router } }) {
    const { valid, rules } = formValidations()
    const { Organization, User } = $FeathersVuex.api
    const createEditForm = ref(null)
    const committee = new Organization(props.editingOrg || { isCommittee: true })

    const saveCommittee = async ({ save, reset }) => {
      try {
        const { id } = await save();
        const { id: userId } = $store.getters['auth/user'];
        const userPatch = new User({ id: userId })
        await userPatch.patch({ data: { organizationId: id } })
        $router.go(0);
      } catch (e) {
        $snackError('Committee Error: ', e)
      }
    }

    return {
      valid,
      rules,
      createEditForm,

      committee,
      saveCommittee
    }
  },
}
</script>

<style>

</style>